import { Link } from "react-router-dom";
import dateFormatter from "../../helpers/dateFormatter";
import Avatar from "../Avatar";

function ArticleMeta({ author, readTime, children, createdAt }) {
	// console.log(createdAt)
	// if(!createdAt) return null
	const { bio, followersCount, following, image, username } = author || {};
	// console.log(createdAt)
	// console.log(readTime)

	return (
		<div className="article-meta">
			<Link
				state={{ bio, followersCount, following, image }}
				// to={`/profile/${username}`}
			>
				<Avatar alt={username} src={'/'+image} />
			</Link>
			<div className="info">
				{/* <Link
					className="author"
					state={{ bio, followersCount, following, image }}
					// to={`/profile/${username}`}
				>
					{username}
				</Link> */}
				<div className="author">{username}</div>
				<span className="date">{dateFormatter(createdAt)}{readTime ? ', ' + readTime : ""}</span>
			</div>
			{children}
		</div>
	);
}

export default ArticleMeta;
