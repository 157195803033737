/*
=========================================================
* States Grid - v1.1.0
=========================================================

* Copyright 2024

Coded by KanukPt
=========================================================

*/

// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
import HorizontalTeamCard from "layouts/sections/components/Cards/TeamCards/HorizontalTeamCard";

// Images
// import team2 from "assets/images/smiley-ekass.jpg";
import principal from "assets/images/bom/principal-20230916_400x400.jpg";

function Team() {
	return (
		<Box
			component="section"
			variant="gradient"
			// bgColor="dark"
			position="relative"
			py={6}
			// px={{ xs: 2, lg: 0 }}
			mx={-2}
			bgcolor="inherit" 
		>
			{/* <Container> */}
				<Grid container px={0}>
					<Grid item md={5} mt={-5} >
						<div className="text-3xl px-3 py-1 rounded-lg" style={{color:"#fff", backgroundColor:"#003da5", borderRadius: "7px!important", padding: "0.75rem .938rem 1.25rem" }}>The Management Team</div>
					</Grid>
					<Grid item md={7} pt={2} pl={2}>
						<div className="tracking-tighter mb-7" style={{fontWeight:400, fontSize:"1.25rem", fontFamily:"Roboto, Helvetica, Arial, sans-serif"}}>
							Guided by the Chairperson of the Board of Management (BOM), Alfred Eshitera, our management team is the backbone of our school&apos;s operations. <br/>
							They are responsible for providing governance and steering the school in a strategic direction that ensures its smooth functioning. <br/><br/>
							The team&apos;s primary role is to ensure that the school is well-equipped with the necessary support and resources. <br/>
							Their goal is to foster an environment that is not only appropriate but also caring, one that sparks enthusiasm and a strong motivation for learning among all our students. <br/>
							Through their efforts, they aim to shape our students into confident and responsible citizens who are highly sought after in society. <br/>
							Their commitment to creating such an environment is a testament to their dedication to the educational growth and personal development of every student in our school. 
						</div>
					</Grid>
				</Grid>
				<Grid container spacing={3}>
					<Grid item xs={12} lg={6}>
						<Box mb={1}>
							<HorizontalTeamCard
								image={""}
								name="Alfred Eshitera"
								position={{ color: "info", label: "BOM Chairperson" }}
								description=""
							/>
						</Box>
					</Grid>
					<Grid item xs={12} lg={6}>
						<MKBox mb={1}>
							<HorizontalTeamCard
								image={principal}
								name="Oyando Isaac Sunguti"
								position={{ color: "info", label: "Pricipal" }}
								description="Kindly join us in this journey of molding our children into better members of the society"
							/>
						</MKBox>
					</Grid>
					<Grid item xs={12} lg={6}>
						<MKBox mb={1}>
							<HorizontalTeamCard
								image={""}
								name="Alice Amwayi"
								position={{ color: "info", label: "Vice Chairperson" }}
								description=""
							/>
						</MKBox>
					</Grid>
					<Grid item xs={12} lg={6}>
						<MKBox mb={1}>
							<HorizontalTeamCard
								image={""}
								name="Eng. Willis Ingari"
								position={{ color: "info", label: "Member" }}
								description=""
							/>
						</MKBox>
					</Grid>
					<Grid item xs={12} lg={6}>
						<MKBox mb={1}>
							<HorizontalTeamCard
								image={""}
								name="Habil Omuka"
								position={{ color: "info", label: "Member" }}
								description=""
							/>
						</MKBox>
					</Grid>
					<Grid item xs={12} lg={6}>
						<MKBox mb={1}>
							<HorizontalTeamCard
								image={""}
								name="Benson Musita"
								position={{ color: "info", label: "Member" }}
								description=""
							/>
						</MKBox>
					</Grid>
					<Grid item xs={12} lg={6}>
						<MKBox mb={1}>
							<HorizontalTeamCard
								image={""}
								name="Sarah Ayako"
								position={{ color: "info", label: "Member" }}
								description=""
							/>
						</MKBox>
					</Grid>
					<Grid item xs={12} lg={6}>
						<MKBox mb={1}>
							<HorizontalTeamCard
								image={""}
								name="Bernard Kutiri"
								position={{ color: "info", label: "Member" }}
								description=""
							/>
						</MKBox>
					</Grid>
					<Grid item xs={12} lg={6}>
						<MKBox mb={1}>
							<HorizontalTeamCard
								image={""}
								name="John Owenga"
								position={{ color: "info", label: "Member" }}
								description=""
							/>
						</MKBox>
					</Grid>
					<Grid item xs={12} lg={6}>
						<MKBox mb={1}>
							<HorizontalTeamCard
								image={""}
								name="Enock Akali"
								position={{ color: "info", label: "Member" }}
								description=""
							/>
						</MKBox>
					</Grid>
					<Grid item xs={12} lg={6}>
						<MKBox mb={1}>
							<HorizontalTeamCard
								image={""}
								name="Rose Amanya"
								position={{ color: "info", label: "Member" }}
								description=""
							/>
						</MKBox>
					</Grid>
					<Grid item xs={12} lg={6}>
						<MKBox mb={1}>
							<HorizontalTeamCard
								image={""}
								name="Michael Arunga"
								position={{ color: "info", label: "Member" }}
								description=""
							/>
						</MKBox>
					</Grid>
					<Grid item xs={12} lg={6}>
						<MKBox mb={1}>
							<HorizontalTeamCard
								image={""}
								name="Consolata Inzoberi"
								position={{ color: "info", label: "Member" }}
								description=""
							/>
						</MKBox>
					</Grid>
					<Grid item xs={12} lg={6}>
						<MKBox mb={1}>
							<HorizontalTeamCard
								image={""}
								name="Tom Akanga"
								position={{ color: "info", label: "Member" }}
								description=""
							/>
						</MKBox>
					</Grid>
					<Grid item xs={12} lg={6}>
						<MKBox mb={1}>
							<HorizontalTeamCard
								image={""}
								name="Daniel Owino"
								position={{ color: "info", label: "PA Chairperson" }}
								description=""
							/>
						</MKBox>
					</Grid>
					{/* <Grid item xs={12} lg={4}>
						<MKBox mb={{ xs: 1, lg: 0 }}>
						<HorizontalTeamCard
							image={team4}
							name="Marquez Garcia"
							position={{ color: "info", label: "JS Developer" }}
							description="Artist is a term applied to a person who engages in an activity deemed to be an art."
						/>
						</MKBox>
					</Grid> */}
				</Grid>
			{/* </Container> */}
		</Box>
	);
}

export default Team;
