/*
=========================================================
* States Grid - v1.1.0
=========================================================

* Copyright 2024

Coded by KanukPt

==========================================================

*/

// @mui material components
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "layouts/sections/page-sections/Navbars/DefaultNavbar";
// import DefaultFooter from "layouts/sections/page-sections/page-footer";
// import Footer from "layouts/sections/page-sections/page-footer/sections/Footer";

// About Us page sections
// import Information from "pages/LandingPages/AboutUs/sections/Information";
// import Team from "pages/LandingPages/AboutUs/sections/Team";
import Overview from "pages/LandingPages/TermsOfUse/sections/Overview";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/princhouse-20240612-1400x600.jpg";
import {Helmet} from "react-helmet";
// import {useLocation} from 'react-router-dom';

function TermsOfUse() {
	// const loc = useLocation();
	return (
		<>
			<Helmet>
				<meta name="description" content="Terms of use" />
				<title>Terms of use Ekambuli High School</title>
				<meta name="og:url" content={window.location.href}></meta>
                <link rel="canonical" href={window.location.href} />
				<link rel="icon" href="/favicon.ico"></link>
				<meta name="og:title" content="Terms of use Ekambuli Secondary School"></meta>
				<meta name="og:description" content="Terms of use Ekambuli Secondary School"></meta>
				<meta name="keywords" content="kenya, khwisero, luanda, kakamega, secondary, school, education, terms"></meta>
				<meta name="og:image" content={window.location.origin+'/images/ekass/gate-1400x600.jpg'}></meta>
				<meta name="robots" content="index,follow"></meta>
            </Helmet>
			<DefaultNavbar
				routes={routes}
				// transparent
				// light
				relative
				sticky
			/>
			<Box
				minHeight="75vh"
				width="100%"
				sx={{
					backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
						`${linearGradient(
							rgba(gradients.dark.main, 0.1),
							rgba(gradients.dark.state, 0.1)
						)}, url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					display: "grid",
					placeItems: "center",
					// marginTop:"6.5rem",
				}}
			>
			</Box>
			{/* <Overview /> */}
			{/* <Card
				sx={{
					p: 2,
					mx: { xs: 2, lg: 3 },
					mt: -8,
					mb: 4,
					boxShadow: ({ boxShadows: { xxl } }) => xxl,
					bgcolor:"inherit",
					// color: 'text.primary',
				}}
			> */}
			<Box p={2} mb={4} sx={{ maxWidth: '100%', flexGrow: 1, mb:"10px", bgcolor:"inherit", border: "0 solid rgba(0, 0, 0, 0.125)" }} className="-mt-16 rounded-xl" >
				<Overview />
			</Box>
		</>
	);
}

export default TermsOfUse;
