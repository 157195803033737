/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* States Grid - v1.1.0
=========================================================

* Copyright 2024

Coded by KanukPt

=========================================================
*/

// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import Container from "@mui/material/Container";

// import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
// import MKBox from "components/MKBox";
// import MKButton from "components/MKButton";
import DefaultNavbar from "layouts/sections/page-sections/Navbars/DefaultNavbar";
// import Footer from "layouts/sections/page-sections/page-footer/sections/Footer";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

import bgImage from "assets/images/volleyball-20240612-1400x600.jpg";
import {Helmet} from "react-helmet";
// import { Diversity1Outlined } from "@mui/icons-material";

const AchievementsPg = () => {
	return (
		<>
			<Helmet>
				<meta name="description" content="Achievements" />
				<title>Achievements Ekambuli High School</title>
				<meta name="og:url" content={window.location.href}></meta>
                <link rel="canonical" href={window.location.href} />
				<link rel="icon" href="/favicon.ico"></link>
				<meta name="og:title" content="Achievements Ekambuli Secondary School"></meta>
				<meta name="og:description" content="Achievements Ekambuli Secondary School"></meta>
				<meta name="keywords" content="kenya, khwisero, luanda, kakamega, secondary, school, education, achievements"></meta>
				<meta name="og:image" content={window.location.origin+'/images/ekass/gate-1400x600.jpg'}></meta>
				<meta name="robots" content="index,follow"></meta>
            </Helmet>
			<DefaultNavbar
				routes={routes}
				sticky
				relative
			/>
			<Box
				minHeight="75vh"
				width="100%"
				
				sx={{
					backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
						`${linearGradient(
						rgba(gradients.dark.main, 0.1),
						rgba(gradients.dark.state, 0.1)
						)}, url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					display: "grid",
					placeItems: "center",
					fontFamily:"cursive",
					// fontSize: "4xl",
					// [breakpoints.down("md")]: {
					// 	fontSize: size["2xl"],
					// },
				}}
			>
				<div className="text-[color:white] text-3xl md:text-4xl text-center text-bold ">
					Everyone Achieves
				</div>
			</Box>
			<Box p={0} mb={4} sx={{ maxWidth: '100%', flexGrow: 1, mb:"10px", bgcolor:"inherit" }} className="-mt-12 rounded-xl mx-5vw">
					<Grid container spacing={2} >
						<Grid item md={5} mt={-4} >
							<div className="rounded-lg text-3xl py-1" style={{color:"#fff", backgroundColor:"#003da5", borderRadius: "7px!important", padding: "0.55rem .938rem 0.55rem" }}>Achievements</div>
						</Grid>
						<Grid item md={7} >
							<div style={{fontSize:"1.25rem"}} className="text-xl tracking-tighter mb-7">
								We are committed to delivering an education that cultivates well-rounded children, preparing them not only to seamlessly integrate into society but also to pursue further professional education. <br/><br/>
								Our mean score is 4.582. <br/>
								We take pride in our ability to send at least ten (10) students to public universities annually, while the remaining students continue their educational journey in various tertiary institutions. <br/><br/>
								We participate and compete favorably in music (Regional level), football, netball, volleyball and take part in scouting and other diverse pursuits.
							</div>
						</Grid>
					</Grid>
			</Box>
		</>
	);
}

export default AchievementsPg;
