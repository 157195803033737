/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* States Grid - v1.1.0
=========================================================

* Copyright 2024

Coded by KanukPt

=========================================================

*/

// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import Typography from "@mui/material/Typography";
// import { useTheme } from '@mui/material/styles';

// import MKBox from "components/MKBox";
import Box from "@mui/material/Box";
// import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "layouts/sections/page-sections/Navbars/DefaultNavbar";
// import DefaultFooter from "layouts/sections/page-sections/page-footer";
// import {Spacer} from "components/spacer";

// // About Us page sections
// import Information from "pages/LandingPages/AboutUs/sections/Information";
// import Team from "pages/LandingPages/AboutUs/sections/Team";
// import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";
// import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter";

// import Footer from "layouts/sections/page-sections/page-footer/sections/Footer";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/class-20240612-1400x600.jpg";
import {Helmet} from "react-helmet";
// import {useLocation} from 'react-router-dom';

function OverviewPg() {
	// const theme = useTheme();
	// const { palette } = theme;
	// const { text } = palette;
	return (
		<>
            <Helmet>
				<title>Overview Ekambuli High School</title>
				<meta name="og:url" content={window.location.href}></meta>
                <link rel="canonical" href={window.location.href} />
				<link rel="icon" href="/favicon.ico"></link>
				<meta name="og:title" content="Overview Ekambuli Secondary School"></meta>
				<meta name="og:description" content="Overview Ekambuli Secondary School"></meta>
				<meta name="keywords" content="kenya, khwisero, luanda, kakamega, secondary, school, education, overview"></meta>
				<meta name="og:image" content={window.location.origin+'/images/ekass/gate-1400x600.jpg'}></meta>
				<meta name="robots" content="index,follow"></meta>
			</Helmet>
			<DefaultNavbar
				routes={routes}
				// light
				relative
				sticky
			/>
			<Box
				minHeight="75vh"
				width="100%"
				sx={{
					backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
						`${linearGradient(
						rgba(gradients.dark.main, 0.1),
						rgba(gradients.dark.state, 0.1)
						)}, url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					display: "grid",
					fontFamily:"cursive",
					placeItems: "center",

				}}
			>
				<div className="text-[color:white] text-2xl md:text-3xl text-center text-bold ">
					Educational excellence in Western Kenya
				</div>
			</Box>
			<Box 
				// sx={(theme) => ({
				// 	backgroundColor: '#fff',
				// 	'&:hover': {
				// 		backgroundColor: '#f5f5f5',
				// 		...theme.applyStyles('dark', {
				// 			backgroundColor: '#333',
				// 		}),
				// 	},
				// 	...theme.applyStyles('dark', {
				// 		backgroundColor: '#000',
				// 	}),
				// 	p: 2,
				// 	mt: -8,
				// 	mb: 4,
				// 	boxShadow: ({ boxShadows: { xxl } }) => xxl,
				// })}

				// sx={{
				// 	p: 2,
				// 	// mx: { xs: 2, lg: 3 },
				// 	mt: -8,
				// 	mb: 4,
				// 	// boxShadow: ({ boxShadows: { xxl } }) => xxl,
				// 	// bgcolor:"inherit",
				// 	// color: 'text'
				// }}
				// p={2} 
				mb={4} 
				sx={{ maxWidth: '100%', flexGrow: 1, mb:"10px", bgcolor:"inherit" }}
				className="-mt-12 rounded-xl mx-5vw"
			>
				{/* <Container> */}
				{/* <Box p={2} mb={4} sx={{ color: 'text', maxWidth: '100%', flexGrow: 1, mb:"10px"}}> */}
					<Grid container spacing={2} >
						<Grid item md={4} mt={-4}>
							<div className="rounded-lg text-3xl py-1 h-max" style={{color:"#fff", backgroundColor:"#003da5", borderRadius: "7px!important", padding: "0.55rem .938rem 0.55rem" }}>Overview</div>
							{/* <div style={{color:"#fff", backgroundColor:"#003da5"}} className="rounded-lg text-3xl px-3 py-1">Overview</div> */}
						</Grid>
						<Grid item md={8} pt={5} pl={5}>
							{/* <MKTypography variant="body1" mb={3}> */}
							<div style={{ fontSize:"1.25rem"}} className="font-semibold tracking-tighter mb-7">
								Ekambuli Secondary School follows the 8-4-4 Curriculum, leveraging the unique opportunities provided by our environment and location in Kenya. 
								We adhere to the Teachers Service Commission (TSC) syllabus in all subject areas.
							</div>
							<div className="text-sch_color1 text-xl font-semibold tracking-tighter">Educational Technology</div>
							<p className="mb-5">
								Our use of educational technology is currently limited.
							</p>
							<div className="text-sch_color1 font-semibold text-xl tracking-tighter">Teachers</div>
							<p className="mb-5">
								We have a dedicated team of twenty (20) TSC teachers and five (5) teachers employed by the Board of Management (BOM).
							</p>
							<div className="text-sch_color1 font-semibold text-xl tracking-tighter">Support staff</div>
							<p className="mb-5">
								We have 30 well-trained and dedicated support staff.
							</p>
							<div className="text-sch_color1 font-semibold text-xl tracking-tighter">Facilities</div>
							<p className="mb-5">
								Our facilities include a library, classrooms, boarding, dining, kitchen, computer lab, science lab, school bus, electricity, piped water, co-curricular activities, and a 20-acre farm. All facilities are secured by a fence and a gate.
							</p>
							<div className="text-sch_color1 font-semibold text-xl tracking-tighter">Supportive Community and Partners</div>
							<p className="mb-5">
								We receive dedicated support from parents/guardians, local MP Hon. Aseka, Khwisero NGCDF, and the community.
							</p>
						</Grid>
					</Grid>
				{/* </Box> */}
				{/* </Container> */}
			</Box>
		</>
	);
}

export default OverviewPg;