/*
=========================================================
* States Grid React - v1.1.0
=========================================================

* Copyright 2024

Coded by KanukPt

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React
import DefaultNavbar from "layouts/sections/page-sections/Navbars/DefaultNavbar";
// import Footer from "layouts/sections/page-sections/page-footer/sections/Footer";

// About Us page sections
// import Information from "pages/LandingPages/AboutUs/sections/Information";
// import Team from "pages/LandingPages/AboutUs/sections/Team";
import Overview from "pages/LandingPages/Privacy/sections/Overview";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/privacy_20230916_1400x600.jpg";
// import bgImage1 from "assets/images/privacy_20230916_480x206.jpg";
import {Helmet} from "react-helmet";
// import {useLocation} from 'react-router-dom';

function Privacy() {
	// const loc = useLocation();
	return (
		<>
			<Helmet>
				<meta name="description" content="Privacy policy." />
				<title>Privacy Ekambuli High School</title>
				<meta name="og:url" content={window.location.href}></meta>
                <link rel="canonical" href={window.location.href} />
				<link rel="icon" href="/favicon.ico"></link>
				<meta name="og:title" content="Privacy Ekambuli Secondary School"></meta>
				<meta name="og:description" content="Privacy Ekambuli Secondary School"></meta>
				<meta name="keywords" content="kenya, khwisero, luanda, kakamega, secondary, school, education, overview"></meta>
				<meta name="og:image" content={window.location.origin+'/images/ekass/gate-1400x600.jpg'}></meta>
				<meta name="robots" content="index,follow"></meta>
            </Helmet>
			<DefaultNavbar
				routes={routes}
				// action={{
				//   type: "external",
				//   route: "https://www.creative-tim.com/product/material-kit-react",
				//   label: "free download",
				//   color: "default",
				// }}
				// transparent
				// light
				relative
				sticky
			/>
			<MKBox
				minHeight="75vh"
				width="100%"
				sx={{
					backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
						`${linearGradient(
							rgba(gradients.dark.main, 0.1),
							rgba(gradients.dark.state, 0.1)
						)}, url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					display: "grid",
					placeItems: "center",
					// sizes: "(max-width: 480px) 480px, 1400px" ,
					// srcSet: `${bgImage1}, ${bgImage}`,
				}}
			>
			</MKBox>
			{/* <Card
				sx={{
					p: 2,
					mx: { xs: 2, lg: 3 },
					mt: -8,
					mb: 4,
					boxShadow: ({ boxShadows: { xxl } }) => xxl,
					bgcolor: 'background.default',
				}}
			> */}
			<Box p={2} mb={4} sx={{ maxWidth: '100%', flexGrow: 1, mb:"10px", bgcolor:"inherit", border: "0 solid rgba(0, 0, 0, 0.125)" }} className="-mt-16 rounded-xl" >
				<Overview />
			</Box>
		</>
	);
}

export default Privacy;
