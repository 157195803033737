/*
=========================================================
* States Grid - v1.1.0
=========================================================

* Copyright 2024

Coded by KanukPt

 =========================================================

*/

// @mui material components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";

// import MKBox from "components/MKBox";
// import Typography from "components/Typography";

import HorizontalTeamCard from "layouts/sections/components/Cards/TeamCards/HorizontalTeamCard";

// Images
import pamela from "assets/images/friends/pamela-a.png";


function Team() {
	return (
		<Box
			component="section"
			variant="gradient"
			position="relative"
			py={6}
			// px={{ xs: 2, lg: 0 }}
			mx={-2}
			bgcolor="inherit" 
		>
			<Grid container px={0}>
				<Grid item md={5} mt={-5} >
					<div className="text-3xl px-3 py-1 rounded-lg" style={{color:"#fff", backgroundColor:"#003da5", borderRadius: "7px!important", padding: "0.75rem .938rem 1.25rem" }}>Friends of the school</div>
				</Grid>
				<Grid item md={7} pt={2} pl={2}>
					<div className="tracking-tighter mb-7" style={{fontWeight:400, fontSize:"1.25rem", fontFamily:"Roboto, Helvetica, Arial, sans-serif"}}>
						We value friends of our school.
					</div>
				</Grid>
			</Grid>
			{/* <Grid container px={2}>
				<Grid item xs={12} sx={{ mb: 6 }}>
					<div className="text-3xl px-3 py-1 rounded-lg" style={{color:"#fff", backgroundColor:"#003da5", borderRadius: "7px!important", padding: "0.75rem .938rem 1.25rem" }}>Friends of the school</div>
					<p className="tracking-tighter mb-7" style={{fontWeight:400, fontSize:"1.25rem", fontFamily:"Roboto, Helvetica, Arial, sans-serif"}}>
						We value friends of our school.
					</p>
				</Grid>
			</Grid> */}
			<Grid container spacing={3}>
				<Grid item xs={12} lg={6}>
					<Box mb={1}>
						<HorizontalTeamCard
							image={pamela}
							name="Pamela Ambundo"
							position={{ color: "info", label: "Friend Of The School" }}
							description=""
						/>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Team;
