import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Link } from "react-router-dom";
// import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Button';
// import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
// import MKBox from "components/MKBox";
// import MKButton from "components/MKButton";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
// import Pagination from 'docs/src/modules/components/Pagination';

import { images, getImgProps } from 'images'

// Images
// import bgImage0_480 from "assets/images/ekass/dinninghall-20240612-480x206.jpg";
// import bgImage0_768 from "assets/images/ekass/dinninghall-20240612-768x330.jpg";
// import bgImage0_1400 from "assets/images/ekass/dinninghall-20240612-1400x600.svg";

// import bgImage0_1400 from "assets/images/ekass/dinninghall-20240612-1400x600.jpg";

// import bgImage1_480 from "assets/images/ekass/gate-480x206.jpg";
// import bgImage1_768 from "assets/images/ekass/gate-768x330.jpg";

// import bgImage1_1400 from "assets/images/ekass/gate-1400x600.jpg";

// import bgImage2_480 from "assets/images/ekass/footbal-20240612-480x206.jpg";
// import bgImage2_768 from "assets/images/ekass/footbal-20240612-768x330.jpg";
// import bgImage2_1400 from "assets/images/ekass/footbal-20240612-1400x600.svg";

// import bgImage2_1400 from "assets/images/ekass/footbal-20240612-1400x600.jpg";

// import bgImage3_480 from "assets/images/ekass/drama-20240612-480x206.jpg";
// import bgImage3_768 from "assets/images/ekass/drama-20240612-768x330.jpg";
// import bgImage3_1400 from "assets/images/ekass/drama-20240612-1400x600.jpg";
// import bgImage3_1400 from "assets/images/ekass/drama-20240612-1400x600.svg";

// import bgImage4_480 from "assets/images/ekass/group_photo_20230916-480x206.jpg";
// import bgImage4_768 from "assets/images/ekass/group_photo_20230916-768x330.jpg";
// import bgImage4_1400 from "assets/images/ekass/group_photo_20230916-1400x600.jpg";

// import bgImage5_480 from "assets/images/ekass/farm-20240612-480x206.jpg";
// import bgImage5_768 from "assets/images/ekass/farm-20240612-768x330.jpg";
// import bgImage5_1400 from "assets/images/ekass/farm-20240612-1400x600.svg";
// import bgImage5_1400 from "assets/images/ekass/farm-20240612-1400x600.jpg";

// import bgImage6_480 from "assets/images/ekass/forest-20240612-480x206.jpg";
// import bgImage6_768 from "assets/images/ekass/forest-20240612-768x330.jpg";
// import bgImage6_1400 from "assets/images/ekass/forest-20240612-1400x600.jpg";

// import bgImage7_480 from "assets/images/ekass/teachers-20240612-480x206.jpg";
// import bgImage7_768 from "assets/images/ekass/teachers-20240612-768x330.jpg";
// import bgImage7_1400 from "assets/images/ekass/teachers-20240612-1400x600.jpg";

// import bgImage8_480 from "assets/images/ekass/science-20240612-480x206.jpg";
// import bgImage8_768 from "assets/images/ekass/science-20240612-768x330.jpg";
// import bgImage8_1400 from "assets/images/ekass/science-20240612-1400x600.jpg";

// import bgImage10_480 from "assets/images/ekass/kitchen-20240612-480x206.jpg";
// import bgImage10_768 from "assets/images/ekass/kitchen-20240612-768x330.jpg";
// import bgImage10_1400 from "assets/images/ekass/kitchen-20240612-1400x600.svg";

// import bgImage9_1400 from "assets/images/ekass/adminblock-20240612-1400x600.jpg";
// import {AlignHorizontalCenter} from '@mui/icons-material';


// const bgImage0_480 = "images/ekass/dinninghall-20240612-480x206.jpg";
// const bgImage0_768 = "images/ekass/dinninghall-20240612-768x330.jpg";
// const bgImage0_1400 = "images/ekass/dinninghall-20240612-1400x600.jpg";

// const bgImage1_480 = "images/ekass/gate-480x206.jpg";
// const bgImage1_768 = "images/ekass/gate-768x330.jpg";
// const bgImage1_1400 = "images/ekass/gate-1400x600.jpg";

// const bgImage2_480 = "images/ekass/footbal-20240612-480x206.jpg";
// const bgImage2_768 = "images/ekass/footbal-20240612-768x330.jpg";
// const bgImage2_1400 = "images/ekass/footbal-20240612-1400x600.jpg";

// const bgImage3_480 = "images/ekass/drama-20240612-480x206.jpg";
// const bgImage3_768 = "images/ekass/drama-20240612-768x330.jpg";
// const bgImage3_1400 = "images/ekass/drama-20240612-1400x600.jpg";

// const bgImage4_480 = "images/ekass/group_photo_20230916-480x206.jpg";
// const bgImage4_768 = "images/ekass/group_photo_20230916-768x330.jpg";
// const bgImage4_1400 = "images/ekass/group_photo_20230916-1400x600.jpg";

// const bgImage5_480 = "images/ekass/farm-20240612-480x206.jpg";
// const bgImage5_768 = "images/ekass/farm-20240612-768x330.jpg";
// const bgImage5_1400 = "images/ekass/farm-20240612-1400x600.jpg";

// const bgImage6_480 = "images/ekass/forest-20240612-480x206.jpg";
// const bgImage6_768 = "images/ekass/forest-20240612-768x330.jpg";
// const bgImage6_1400 = "images/ekass/forest-20240612-1400x600.jpg";

// const bgImage7_480 = "images/ekass/teachers-20240612-480x206.jpg";
// const bgImage7_768 = "images/ekass/teachers-20240612-768x330.jpg";
// const bgImage7_1400 = "images/ekass/teachers-20240612-1400x600.jpg";

// const bgImage8_480 = "images/ekass/science-20240612-480x206.jpg";
// const bgImage8_768 = "images/ekass/science-20240612-768x330.jpg";
// const bgImage8_1400 = "images/ekass/science-20240612-1400x600.jpg";

// const bgImage9_480 = "images/ekass/kitchen-20240612-480x206.jpg";
// const bgImage9_768 = "images/ekass/kitchen-20240612-768x330.jpg";
// const bgImage9_1400 = "images/ekass/kitchen-20240612-1400x600.jpg";


const cimages = [
	{
		label: 'Drama club',
		imess: "Seize. Opportunity. Learn.",
		moreurl: "/about-us",
		labelurl: "more about us",
		imgPath: images.drama,
		// imgPath: `${bgImage0_480}, ${bgImage0_768}, ${bgImage0_1400}`,
	},
	{
		label: 'School Gate',
		imess: "",
		moreurl: "",
		labelurl: "",
		imgPath: images.gate,
	},
	{
		label: 'School Teachers',
		imess: "Be in demand.",
		// moreurl: "/blog/article/554acca8",
		moreurl: "/blog",
		labelurl: "the sleeping giant",
		imgPath: images.teachers,
	},
	{
		label: 'Football team',
		imess: "Be of high quality.",
		moreurl: "/why-us/achieve",
		labelurl: "our achievements",
		imgPath: images.football,
	},
	{
		label: 'School photo',
		imess: "Jitihadi. Juhudi. Bidii.",
		moreurl: "/why-us/approach",
		labelurl: "Jitihadi/Juhudi/Bidii",
		imgPath: images.photo,
	},
	{
		label: 'School photo',
		imess: "Confident. Well-adjusted. Responsible.",
		moreurl: "/why-us/approach",
		labelurl: "student leadership",
		imgPath: images.kitchen,
	},
	{
		label: 'School farm',
		imess: "Commitment. Mission. Integrity.",
		moreurl: "/why-us/approach",
		labelurl: "our aproach",
		imgPath: images.farm,
	},
	{
		label: 'School forest',
		imess: 'Education. Innovation. Talents.',
		moreurl: "/about-us",
		labelurl: "more about us",
		imgPath: images.forest,
	},
	{
		label: 'Teachers meeting',
		imess: 'Results. Universities. Colleges.',
		// moreurl: "/blog/article/first-class-career-guidance-wshfy4a",
		moreurl: "/blog",
		labelurl: "Career guidance",
		imgPath: images.teachers,
	},
	{
		label: 'Science fair preparations',
		imess: "Discover. Self-drive. Strive.",
		moreurl: "/why-us/achieve",
		labelurl: "our results",
		imgPath: images.science,
	},
	{
		label: 'Admnistration Block',
		imess: "Systems. Staff.  Trust.",
		moreurl: "/why-us/approach",
		labelurl: "our approach",
		imgPath: images.adminblk,
	},
];

function SwipeableTextMobileStepper() {
	const theme = useTheme();
	const [activeStep, setActiveStep] = React.useState(0);
	// console.dir(localStorage.theme);

	// const handleNext = () => {
	// 	setActiveStep((prevActiveStep) => prevActiveStep + 1);
	// };

	// const handleBack = () => {
	// 	setActiveStep((prevActiveStep) => prevActiveStep - 1);
	// };

	const handleStepChange = (step) => {
		setActiveStep(step);
	};
	// const { palette } = theme;
	// const { upvote } = palette;
	// const color = 'bg-green-500';
	return (
		// <Box sx={{ maxWidth: '100%', height:"auto", flexGrow: 1}}>
		<div className="h-auto max-w-full">
			<AutoPlaySwipeableViews
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={activeStep}
				onChangeIndex={handleStepChange}
				enableMouseEvents
			>
				{cimages.map((step, index) => (
					Math.abs(activeStep - index) <= 10 ? (		// position:"relative", display:"flex", justifyContent:"center", 
						<div key={step.label} style={{position:"relative"}} >
							{/* <img src={step.imgPath} alt={step.label} style={{opacity: 0.7}} /> */}
							<img
								{...getImgProps(step.imgPath, {
									style:"",
									className: 'rounded-lg object-cover w-full',
									widths: [256, 550, 700, 900, 1300, 1800],
									sizes: [
										'(max-width: 320px) 256px',
										'(min-width: 321px) and (max-width: 1023px) 80vw',
										'(min-width: 1024px) 410px',
										'850px',
									],
								})}
							/>
							<div className="bg-secondary text-center absolute flex top-[3%] justify-center w-full text-xl md:text-4xl " >
								{step.imess}
							</div>
							{ step.labelurl &&
								<div className="absolute bottom-3 flex w-full justify-center sm:text-xl md:text-4xl ">
									<button style={{ paddingLeft:"4px", paddingRight:"4px", background: "linear-gradient(90deg, #0700b8 0%, #00ff88 100%)", color:"white"}}>
										<Link
											className="nav-link"
											to={step.moreurl}
										>
											{step.labelurl}
										</Link>
									</button>
								</div>
							}
						</div>
					) : null
				))}
			</AutoPlaySwipeableViews>
			{/* <Box sx={{width: "100%, 0.46" }}>
				<img {...attributeElements} src={require('assets/images/ekass/adminblock-20240612-1400x600_.svg').default} alt='mySvgImage' />
			</Box> */}
			{/* <MobileStepper
				steps={maxSteps}
				position="static"
				activeStep={activeStep}
				variant='text'
				nextButton={
					<Button
						size="small"
						onClick={handleNext}
						disabled={activeStep === maxSteps - 1}
					>
						Next
						{theme.direction === 'rtl' ? (
							<KeyboardArrowLeft />
						) : (
							<KeyboardArrowRight />
						)}
					</Button>
				}
				backButton={
					<Button size="small" onClick={handleBack} disabled={activeStep === 0}>
						{theme.direction === 'rtl' ? (
							<KeyboardArrowRight />
						) : (
							<KeyboardArrowLeft />
						)}
						Back
					</Button>
				}
			/> */}
		</div>
	);
}

export default SwipeableTextMobileStepper;
