import React from "react";
import { Link } from "react-router-dom";
// @mui material components
// import Container from "@mui/material/Container";
import Button from "@mui/material/Button"
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
// import { useTheme } from '@mui/material/styles';
// import CenteredBlogCard from "layouts/sections/components/Cards/BlogCards/BackgroundBlogCard";
// import {Grid} from "components/grid";

// Material Kit 2 React components
// import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import DefaultNavbar from "layouts/sections/page-sections/Navbars/DefaultNavbar";

// import axios from "axios";

// Presentation page sections
// import Counters from "pages/LandingPages/Home/sections/Counters";
// import Information from "pages/Presentation/sections/Information";
// import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
// import Pages from "pages/Presentation/sections/Pages";
// import Testimonials from "pages/Presentation/sections/Testimonials";
// import Download from "pages/Presentation/sections/Download";

// Presentation page components
import SwipeableTextMobileStepper from "pages/LandingPages/Home/sections/Carousel";
// import Footer from "layouts/sections/page-sections/page-footer/sections/Footer";

import {Spacer} from "components/spacer";
// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";
import {IntroductionSection} from "pages/LandingPages/Home/sections/introduction-section";

// Images
// import bgImage3 from "assets/images/ekass/drama-20240612-1400x600.svg";
// import AdminBlock from "./sections/images/adminblock-20240612-1400x600";

// import mdx from '@mdx-js/esbuild'
// import esbuild from 'esbuild'
// import calculateReadingTime from 'reading-time'

import {HeroSection} from "layouts/sections/hero-section";
// import {BlogSection} from "pages/LandingPages/Home/sections/blogSection";
import {Helmet} from "react-helmet";
// import { images, getImgProps } from 'images';
// import {useLocation} from 'react-router-dom';
// import getArticles from "layouts/sections/components/Articles/services/getArticles";
// import { useAuth } from "context/AuthContext";
// import AdminBlock from "/w_200,q_60/stripmall/img/home/spencer-imbrock-487035-unsplash.jpg"

const Home = () => {
	// const loc = useLocation();

	// const { palette } = theme;
	// const { text } = palette;
	// const { headers = null } = useAuth() || {};
	// let mdx = {};
	// React.useEffect(() => {
		// fetch('http://localhost:3000/api/getmessage')
		// 	.then(function(response) {
		// 		// The response is a Response instance.
		// 		// You parse the data into a useable format using `.json()`
		// 		return response.json()
		// 	})
		// 	.then((data) => {
		// 		console.dir(data)
		// 		setMess(data)
		// 	})
		// fetch('http://localhost:3000/api/showcaptcha/images/blog/drama-20240612-1400x600.jpg/w_700,q_auto,f_auto')
		// 	.then(res => { return res.text() })
		// 	.then(content => {
		
		// 		setImage("data:application/octet-stream," + encodeURIComponent(content))
		// 		// window.open(uriContent, 'somefile', "popup");
		// 	});
		// axios({
		// 	method: 'get',
		// 	url: 'http://localhost:3000/api/getcaptchak/images/blog/drama-20240612-1400x600.jpg/w_700,q_auto,f_auto',
		// 	// responseType: 'stream'
		// 	// responseType: 'blob'
		// 	responseType: 'image/jpeg'
		// 	// responseType: 'text/html'
		// })
		// .then(function (response) {
		// 	console.dir(response)
        //     const url = window.URL.createObjectURL(new Blob([response]));
        //     const link = document.createElement('a');
        //     link.href = url;
        //     const fileName = `${+ new Date()}.jpg`// whatever your file name .
        //     link.setAttribute('download', fileName);
        //     document.body.appendChild(link);
        //     link.click();
        //     link.remove();// you need to remove that elelment which is created before.
		// });
	// }, []);
	return (
		<>
			<Helmet>
				<meta name="description" content="We are Ekambuli High School. We assist our learners in their effort to be of high quality." />
				<title>Ekambuli High School</title>
				<meta name="og:url" content={window.location.href}></meta>
                <link rel="canonical" href={window.location.href} />
				<link rel="icon" href="/favicon.ico"></link>
				<meta name="og:title" content="Ekambuli Secondary School"></meta>
				<meta name="og:description" content="Ekambuli Secondary School"></meta>
				<meta name="keywords" content="kenya, khwisero, luanda, kakamega, secondary, school, education"></meta>
				<meta name="og:image" content={window.location.origin+'/images/ekass/gate-1400x600.jpg'}></meta>
				<meta name="robots" content="index,follow"></meta>
            </Helmet>
			<DefaultNavbar
				routes={routes}
				brand="Ekambuli High School"
				// action={{
				// 	type: "external",
				// 	route: "https://www.creative-tim.com/product/material-kit-react",
				// 	label: "free download1",
				// 	color: "info",
				// }}
				// transparent
				relative
				sticky
			/>
			{/* <main className="px-8 xs:mx-2vw lg:mx-10vw"> */}
			<div className="max-w-4xl mx-auto">
				{/* <Grid container spacing={3} alignItems="center"> */}
				<div className="col-span-full">
					<SwipeableTextMobileStepper />
				</div>
				{/* sx={{fontFamily:"ui-sans-serif,system-ui,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji"}} */}
				{/* <Spacer size="2xs" className="col-span-full" />
				<div className="col-span-full">
					<Typography variant="h2" >
						<div className="tw-invert">
							We assist our learners in their effort to be of high quality.
						</div>
					</Typography>
				</div> */}
				{/* <div className="col-span-full">
					<Welcome />
				</div> */}
				{/* <Spacer size="2xs" className="col-span-full" /> */}
				{/* <div className="grid grid-cols-subgrid gap-4 col-span-2" >
					<Typography position="relative">
						<MKBox component="img" src={bgImage3} alt="" width="100%" />
					</Typography>
				</div> */}
			</div>
			{/* </MKBox> */}
			<IntroductionSection />
			<div className="col-span-full mt-12 xs:col-start-6 lg:mt-0 mb-12">
				<HeroSection
					title="’Sleeping Giant’ awakens."
					subtitle={
						<>
							<span className="text-xl">
								It is yet another time that we take stock of what we have been able to achieve over the years. 
							</span>
						</>
					}
					imageSize="medium"
					arrowUrl="/blog"
					arrowDirection="right"
					arrowLabel="Know more from our blog"
				/>
			</div>
			<Spacer size="6xs" className="col-span-full" />
			<div className="grid mx-7vw col-span-full gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
				<div className="my-4">
					<Card sx={{bgcolor:"inherit"}}>
						<Box borderRadius="lg" mx={0} mt={-3}>
							<img className="rounded-lg min-h-32 w-100% z-1 relative"
								// component="img"
								src="/images/ekass/class-20240612-1400x600.jpg"
								alt="overview"
							/>
							<Box
								borderRadius="lg"
								shadow="md"
								width="100%"
								height="100%"
								position="absolute"
								left={0}
								top={0}
								sx={{
									transform: "scale(0.94)",
									filter: "blur(12px)",
									backgroundSize: "cover",
								}}
							/>
						</Box>
						<Box p={3} mt={-1} textAlign="center">
							<Typography display="inline" variant="h5" textTransform="capitalize" fontWeight="regular">
								Overview
							</Typography>
							<Box mt={1} mb={3}>
								<Typography variant="body2" component="p">
									We follow the 8-4-4 Curriculum, leveraging the unique opportunities provided by our environment. 
								</Typography>
							</Box>
							<Button
								component={Link}
								to="/why-us/overview"
								variant="gradient"
								size="small"
								color="info"
							>
								find out more
							</Button>
						</Box>
					</Card>
				</div>
				<div className="my-4">
					<Card sx={{bgcolor:"inherit"}}>
						<Box borderRadius="lg" mt={-3}>
							<img className="rounded-lg min-h-32 w-100% z-1 relative"
								// component="img"
								src="/images/ekass/drama-20240612-mixed.jpg"
								alt="Our Approach"
								// borderRadius="lg"
								// width="100%"
								// position="relative"
								// zIndex={1}
							/>
							<Box
								borderRadius="lg"
								shadow="md"
								width="100%"
								height="100%"
								position="absolute"
								left={0}
								top={0}
								sx={{
									// backgroundImage: `url(images/ekass/teachers-20240612-1400x600.jpg)`,
									transform: "scale(0.94)",
									filter: "blur(12px)",
									backgroundSize: "cover",
								}}
							/>
						</Box>
						<Box p={3} mt={-1} textAlign="center">
							<Typography display="inline" variant="h5" textTransform="capitalize" fontWeight="regular">
								Our Approach
							</Typography>
							<Box mt={1} mb={3}>
								<Typography variant="body2" component="p">
									We firmly believe that the best learning outcomes are achieved through intrinsic motivation and self-drive.
								</Typography>
							</Box>
							<Button
								component={Link}
								to="/why-us/approach"
								variant="gradient"
								size="small"
								color="info"
							>
								find out more
							</Button>
						</Box>
						{/* <CenteredBlogCard
							sx={{ml: "auto", mt: { xs: 3, lg: 0 }}}
							image="images/ekass/teachers-20240612-1400x600.jpg"
							title="Our Approach"
							description="We firmly believe that the best learning outcomes are achieved through intrinsic motivation and self-drive."
							action={{
								type: "internal",
								route: "/why-us/approach",
								color: "info",
								label: "find out more",
							}}
						/> */}
					</Card>
				</div>
				{/* border-top: 6px solid #6342d2;
    			border-radius: 4%; */}
				<div className="my-4">
					{/* <div className="border-t rounded-xl border-t-4 border-blue-800"> */}
					<Card sx={{bgcolor:"inherit"}}>
						<Box borderRadius="lg" mt={-3}>
							<img className="rounded-lg min-h-32 w-100% z-1 relative"
								// component="img"
								src="/images/ekass/volleyball-20240612-1400x600.jpg"
								alt="Our Achievements"
								// borderRadius="lg"
								// width="100%"
								// position="relative"
								// zIndex={1}
							/>
							<Box
								borderRadius="lg"
								shadow="md"
								width="100%"
								height="100%"
								position="absolute"
								left={0}
								top={0}
								sx={{
									// backgroundImage: `url(images/ekass/teachers-20240612-1400x600.jpg)`,
									transform: "scale(0.94)",
									filter: "blur(12px)",
									backgroundSize: "cover",
								}}
							/>
						</Box>
						<Box p={3} mt={-1} textAlign="center">
							<Typography display="inline" variant="h5" textTransform="capitalize" fontWeight="regular">
								Our Achievements
							</Typography>
							<Box mt={1} mb={3}>
								<Typography variant="body2" component="p">
									We are committed to delivering an education that cultivates well-rounded children.
								</Typography>
							</Box>
							<Button
								component={Link}
								to="/why-us/achieve"
								variant="gradient"
								size="small"
								color="info"
							>
								find out more
							</Button>
						</Box>
					</Card>
				</div>
			</div>
			<Spacer size="3xs" className="col-span-full" />
			<div className="grid-cols-1 place-content-center mx-7vw">
				<div className="col-span-full md:col-span-6 text-center">
					<Typography variant="h4" fontWeight="bold" mb={0.5}>
						Thank you for your support!
					</Typography>
					<Typography variant="body1">
						We deliver the best learning experience.
					</Typography>
				</div>
				<div className="col-span-full md:col-span-6 text-center"
					style={{ textAlign: { lg: "right" } }}
				>
					<MKSocialButton
						component="a"
						href="https://twitter.com/intent/tweet?text=Check Ekambuli High School website&url=https//www.ekambulihighschool.sc.ke"
						target="_blank"
						color="twitter"
						sx={{ mr: 1 }}
						>
						<i className="fab fa-twitter" />
						&nbsp;Tweet
					</MKSocialButton>
					<MKSocialButton
						component="a"
						href="https://www.facebook.com/sharer/sharer.php?u=https://www.ekambulihighschool.sc.ke/"
						target="_blank"
						color="facebook"
						sx={{ mr: 1 }}
					>
						<i className="fab fa-facebook" />
						&nbsp;Share
					</MKSocialButton>
				</div>
			</div>
		</>
	);
}
// Home.propTypes = {
//     toggleMode: PropTypes.func
// };
export default Home;
