/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
// import PropTypes from 'prop-types';
// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { default as Linkm } from "@mui/material/Link";
import Stack from "@mui/material/Stack";
// import { useTheme } from '@mui/material/styles';

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

// Material Kit 2 React components
// import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";

import { Link } from "react-router-dom";
// import Brightness4Icon from '@mui/icons-material/Brightness4';
// import Brightness7Icon from '@mui/icons-material/Brightness7';
// import ToggleColorMode from 'App';
// import { ColorModeContext } from 'App.jsx';
// import {BorderColor} from '@mui/icons-material';

// type Props = {
// 	setMode: () => void;
// };
  
function Footer() {
	// const [mode, setMode] = React.useState("");
	// const theme = useTheme();
	// const colorMode = React.useContext(ColorModeContext);
	// const toggleMode = (e) => {
	// 	// console.log(theme.palette.mode)
	// 	e.preventDefault();
	// 	e.stopPropagation();
    //     colorMode.toggleColorMode();
	// }
	//  sx={{backgroundColor:grey[50], color: 'text.primary'}}
	// console.log(theme.palette.mode)
	return (
		<Box py={1} pl={0} px={'7vw'} mt={8} sx={{bgcolor: 'background.default', borderTop:"1px solid gray"}}>
			{/* <Container> */}
				<Grid container px={0}>
					<Grid
						item
						xs={12}
						lg={8}
						textAlign={{ xs: "center", lg: "left" }}
						// mr="auto"
						mb={{ xs: 1, lg: 0 }}
					>
						<Typography variant="h6" textTransform="uppercase" mb={{ xs: 2, lg: 3 }}>
							Ekambuli High School
						</Typography>
						<Typography variant="body1" sx={{color:'text'}}>Khwisero, Kakamega County, Kenya</Typography>
						<Typography variant="body2" sx={{color: 'text'}}>PO Box 65-50307 Luanda</Typography>
						<Typography variant="body2" sx={{color: 'text'}}><LocalPhoneIcon/>+254 722 445053</Typography>
						<Typography variant="body2" sx={{color: 'text'}}><EmailIcon/>info@ekambulihighschool.sc.ke</Typography>
						<Stack
							component="ul"
							direction="row"
							flexWrap="wrap"
							spacing={3}
							justifyContent={{ xs: "center", lg: "flex-start" }}
							pl={0}
							mt={2}
							mb={3}
							sx={{ listStyle: "none", fontSize: "0.6rem", opacity: "0.8", fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}
						>
							<Box component="li">
								<Typography  
									variant="button"
									fontWeight="regular"
									opacity={0.8}
									component={Link}
									to="/"
									// target="_blank"
									// rel="noreferrer"
									sx={{color: 'text'}}
								>
									Home
								</Typography>
							</Box>
							<Box component="li">
								<Typography 
									variant="button"
									fontWeight="regular"
									opacity={0.8}
									component={Link}
									to="/about-us"
									// target="_blank"
									// rel="noreferrer"
									sx={{color: 'text'}}
								>
									About Us
								</Typography>
							</Box>
							<Box component="li">
								<Typography 
									variant="button"
									fontWeight="regular"
									opacity={0.8}
									component={Link}
									to="/terms-of-use"
									// target="_blank"
									// rel="noreferrer"
									sx={{color: 'text'}}
								>
									Terms of Use
								</Typography>
							</Box>
							<Box component="li">
								<Typography 
									variant="button"
									fontWeight="regular"
									opacity={0.8}
									component={Link}
									to="/privacy-policy"
									// target="_blank"
									// rel="noreferrer"
									sx={{color: 'text'}}
								>
									Privacy Policy
								</Typography>
							</Box>
							{/* <Box component="li">
								<Typography
									variant="button"
									fontWeight="regular"
									opacity={0.8}
									component={Link}
									to="/why-us/approach#motto"
									// href="/why-us#motto"
									// target="_blank"
									// rel="noreferrer"
								>
									Our Motto
								</Typography>
							</Box> */}
						</Stack>
						<Typography variant="button" mb={4} opacity={0.8} sx={{color: 'text'}}>
							Copyright © {(new Date().getFullYear())} Ekambuli High School.
						</Typography>
					</Grid>
					<Grid item xs={12} lg={4} ml="auto" textAlign={{ xs: "center", lg: "right" }}>
						<Typography variant="body2" my={3} sx={{fontWeifgt:500, color: 'text'}}>
							Embark on a journey with us where learning leads to excellence, and your skills will always be in high demand.
						</Typography>
						<Typography 
							component={Linkm}
							href="https://x.com/home"
							target="_blank"
							rel="noreferrer"
							variant="body2"
							// color="dark"
							opacity={0.5}
							mr={3}
							sx={{color: 'text'}}
						>
							<TwitterIcon fontSize="small" />
							{/* <i className="fab fa-twitter" /> */}
						</Typography>
						<Typography 
							component={Linkm}
							href="https://www.facebook.com/"
							target="_blank"
							rel="noreferrer"
							variant="body2"
							// color="dark"
							opacity={0.5}
							mr={3}
							sx={{color: 'text'}}
						>
							<FacebookIcon  fontSize="small" />
							{/* <i className="fab fa-pinterest" /> */}
						</Typography>
						<Typography 
							component={Linkm}
							href="https://www.youtube.com/"
							target="_blank"
							rel="noreferrer"
							variant="body2"
							opacity={0.5}
							mr={3}
							sx={{color: 'text'}}
						>
							<YouTubeIcon  fontSize="small" />
							{/* <i className="fab fa-github" /> */}
						</Typography>
						{/* <Typography sx={{cursor:"pointer", display:"inline", color:'text'}}
							variant="body2"
							opacity={0.5}
							mr={3}
							ml={5}
							onClick={toggleMode}
						>
							{theme.palette.mode === 'dark' ? <Brightness7Icon fontSize="small"/> : <Brightness4Icon fontSize="small"/>}
						</Typography> */}
					</Grid>
					<Grid item xs={12} mx="auto" textAlign={{ lg: "right", display:"flex", justifyContent:"center", marginTop:"15px" }}>
						{/* <div className="col-span-full mt-4 block font-normal"> */}
							<a href="mailto:info.statesgrid.com" target="_blank" rel="noopener noreferrer" className="inline-flex bg-transparent text-sm w-fit mx-auto mt-2" style={{color:'text'}} >
							<span className="mt-1">Powered by</span>
								<span className="w-24 h-8 float-right bg-[url('/images/statesgrid_logo.svg')] bg-no-repeat bg-center"></span>
							</a>
						{/* </div> */}
					</Grid>
				</Grid>
			{/* </Container> */}
		</Box>
	);
}
// Footer.propTypes = {
//     toggleMode: PropTypes.func
// };
export default Footer;
