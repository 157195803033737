import { useFeedContext } from "../../context/FeedContext";

function TagButton({ tagsList }) {
	const { changeTab } = useFeedContext();

	const handleClick = (e) => {
		changeTab(e, "tag");
	};
	// console.dir(tagsList)
	return tagsList.slice(0, 50).map((tag) => (
		// <button className="tag-pill tag-default" key={name} onClick={handleClick}>
		//   {name}
		// </button>
		<a href="" key={tag} className="tag-pill tag-default"
			onClick={(e) => {
				e.preventDefault()

				handleClick(e)
			}}
		>
			{tag}
		</a>
	));
}

export default TagButton;
