/*
=========================================================
* States Grid React - v1.1.0
=========================================================

* Copyright 2024

* Coded by KanukPt

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
// import MKBox from "components/MKBox";
import Typography from "components/MKTypography";


// Material Kit 2 React
import DefaultNavbar from "layouts/sections/page-sections/Navbars/DefaultNavbar";
// import DefaultFooter from "layouts/sections/page-sections/page-footer";

// About Us page sections
import Information from "pages/LandingPages/AboutUs/sections/Information";
import Team from "pages/LandingPages/AboutUs/sections/Team";
import Friends from "pages/LandingPages/AboutUs/sections/Friends";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";
// import Footer from "layouts/sections/page-sections/page-footer/sections/Footer";

// Images
import bgImage from "assets/images/drama-20240612.svg";

import {Helmet} from "react-helmet";
// import {useLocation} from 'react-router-dom';
import {Spacer} from "components/spacer";

const AboutUs = () => {
	// const loc = useLocation();
	// console.dir(props.ttl)
	return (
		<>
            <Helmet>
				<meta name="description" content="About Ekambuli High School" />
				<title>About Ekambuli High School</title>
				<meta name="og:url" content={window.location.href}></meta>
                <link rel="canonical" href={window.location.href} />
				<link rel="icon" href="/favicon.ico"></link>
				<meta name="og:title" content="About Ekambuli Secondary School"></meta>
				<meta name="og:description" content="About Ekambuli Secondary School"></meta>
				<meta name="keywords" content="kenya, khwisero, luanda, kakamega, secondary, school, education, about"></meta>
				<meta name="og:image" content={window.location.origin+'/images/ekass/gate-1400x600.jpg'}></meta>
				<meta name="robots" content="index,follow"></meta>
            </Helmet>
			<DefaultNavbar
				// sx={{marginTop:0, paddingTop:0}}
				routes={routes}
				// action={{
				//   type: "external",
				//   route: "https://www.creative-tim.com/product/material-kit-react",
				//   label: "free download",
				//   color: "default",
				// }}
				// transparent
				// light
				relative
				sticky
			/>
			<Box
				minHeight="75vh"
				width="100%"
				sx={{
					backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
						`${linearGradient(
						rgba(gradients.dark.main, 0.6),
						rgba(gradients.dark.state, 0.6)
						)}, url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					display: "grid",
					placeItems: "center",
					// marginTop:"6.5rem",
				}}
			>
				{/* <Grid
					container
					item
					xs={12}
					lg={8}
					justifyContent="center"
					alignItems="center"
					flexDirection="column"
					sx={{ mx: "auto", textAlign: "center" }}
				> */}
					<Typography
						variant="h1"
						color="white"
						sx={({ breakpoints, typography: { size } }) => ({
							[breakpoints.down("md")]: {
								fontSize: size["3xl"],
							},
						})}
					>
						An amazing school
					</Typography>
				{/* </Grid> */}
			</Box>
			<Box mb={4} sx={{ maxWidth: '100%', flexGrow: 1, mb:"10px", bgcolor:"inherit", border: "0 solid rgba(0, 0, 0, 0.125)" }} className="p-3 -mt-16 rounded-xl mx-7vw" >
				{/* sx={{
					p: 2,
					mx: { xs: 2, lg: 3 },
					// mt: -8,
					mb: 4,
					boxShadow: ({ boxShadows: { xxl } }) => xxl,
					bgcolor:"inherit",
					color: 'text.primary',
				}} */}
				<Stack direction="column">
					<Information />
					<Spacer size="6xs" className="col-span-full" />
					<Team />
				</Stack>
			</Box>
			<Spacer size="6xs" className="col-span-full" />
			<Box mb={4} sx={{ maxWidth: '100%', flexGrow: 1, mb:"10px", bgcolor:"inherit", border: "0 solid rgba(0, 0, 0, 0.125)" }} className="p-3 rounded-xl, mx-7vw" >
				<Stack direction="column">
					<Friends />
				</Stack>
			</Box>
		</>
	);
}

export default AboutUs;
