/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* States Grid - v1.1.0
=========================================================

* Copyright 2024

Coded by KanukPt

=========================================================

*/

// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import { useTheme } from '@mui/material/styles';

// import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
import DefaultNavbar from "layouts/sections/page-sections/Navbars/DefaultNavbar";

// import classnames from 'classnames'

// Routes
import routes from "routes";

import bgImage from "assets/images/drama-20240612.jpg";
import {Helmet} from "react-helmet";
// import {useLocation} from 'react-router-dom';

const ApproachPg = () => {
	// const mode = document.documentElement.classList
	// const theme = useTheme();
	// console.log('pages.landing/whyus/pages/approach/mode:', mode);
	// console.dir(theme.palette)
	// const main = theme.palette.school.main
	// const light = theme.palette.school.light
	// console.log(theme.palette.mode)
	// console.log(theme.palette.mode === 'dark' ? main : light)
	// console.log(main, light)
	return (
		<>
            <Helmet>
				<title>Our approach Ekambuli High School</title>
				<meta name="og:url" content={window.location.href}></meta>
                <link rel="canonical" href={window.location.href} />
				<link rel="icon" href="/favicon.ico"></link>
				<meta name="og:title" content="Approach Ekambuli Secondary School"></meta>
				<meta name="og:description" content="Approach Ekambuli Secondary School"></meta>
				<meta name="keywords" content="kenya, khwisero, luanda, kakamega, secondary, school, education, approach"></meta>
				<meta name="og:image" content={window.location.origin+'/images/ekass/gate-1400x600.jpg'}></meta>
				<meta name="robots" content="index,follow"></meta>
			</Helmet>
			<DefaultNavbar
				routes={routes}
				sticky
				relative
			/>
			<Box 
				minHeight="75vh"
				width="100%"
				// p={2}
				// mt={-8}
				// mb={4}
				sx={{
					backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
						`${linearGradient(
						rgba(gradients.dark.main, 0.1),
						rgba(gradients.dark.state, 0.1)
						)}, url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					display: "grid",
					placeItems: "center",
					fontFamily:"cursive",
				}}
			>
				<div className="text-[color:white] text-3xl md:text-4xl text-center text-bold "
					// variant="h1"
					// color="white"
					// sx={({ breakpoints, typography: { size } }) => ({
					// 	[breakpoints.down("md")]: {
					// 		fontSize: size["2xl"],
					// 	},
					// })}
				>
					For best learning outcomes
				</div>
			</Box>
			<Box p={0} mb={4} sx={{ maxWidth: '100%', flexGrow: 1, mb:"10px", bgcolor:"inherit" }} className="-mt-12 rounded-xl mx-5vw" >
				{/* p={2} mb={4} sx={{ maxWidth: '100%', flexGrow: 1, mb:"10px" }} */}
				<Grid container spacing={2} >
					<Grid item md={4} mt={-4}>
						{/* <Typography variant="h2" sx={{color:"#fff", backgroundColor:"#003da5", borderRadius: "7px!important", padding: "0.55rem .938rem 0.55rem" }}>Our Approach</Typography> */}
						<div className="rounded-lg text-3xl py-1" style={{color:"#fff", backgroundColor:"#003da5", borderRadius: "7px!important", padding: "0.55rem .938rem 0.55rem" }}>Our Approach</div>
					</Grid>
					<Grid item md={8}>
						<Typography component={'p'} sx={{ mb:"1.75rem" }} >
							{/* className="text-sch_colorl" */}
							{/* <Typography component={'p'} sx={{ mb:"1.75rem", color: theme.palette.mode === 'dark' ? main : light }} > */}
							{/* color: theme.palette.mode === 'dark' ? main : light }} > */}
							{/* className={classNames({ `main`: theme.palette.mode === 'dark', light: theme.palette.mode === 'light'})}> */}
							{/* <Typography variant="body1" component="p" sx={{color:palette.sch_text}}> */}
							{/* <MKTypography variant="div" mb={3} sx={{fontWeight:"400", fontFamily: "Arial Regular"}}> */}
							We firmly believe that the best learning outcomes are achieved through intrinsic motivation and self-drive. 
							We encourage students to join the boarding section for more teacher interaction. 
							We have engaged a team of experienced examiners, implemented the use of examination cards, supported our teachers to train as examiners in various subjects and launched a thorough revision strategy, exposing student to past papers and mock exams from top schools. 
						</Typography>
						<div className="text-sch_color1 text-xl tracking-tighter">Our Motto</div>
						<p className="tracking-tighter mb-7">
							Effort Breeds Success.
						</p>
						<div className="text-sch_color1 text-xl tracking-tighter">Our Vision</div>
						<p className="tracking-tighter mb-7">
							To be a Center of Exellence.
						</p>
						<div className="text-sch_color1 text-xl tracking-tighter">Our Mission</div>
						<p className="tracking-tighter mb-7">
							To educate and nurture reliable members of society. <br/><br/>
							We offer both the 8-4-4 and CBS education systems. <br/><br/>
							Beyond academics, our school shapes our students through a variety of co-curricular activities. <br/>
							We participate and compete at a high level in music (at the regional level), football, netball, volleyball, scouting, and more.<br/><br/>
							We are proud of our dedicated team of teaching and non-teaching staff who work tirelessly to implement all school-planned activities. <br/><br/>
							Guided by the principle &quot;Do unto others...,&quot; we believe that what we do for ourselves dies with us, but what we do for others endures even after we are gone. 
							This belief drives our commitment to our students and their education.
						</p>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default ApproachPg;
