// import { type TransformerOption } from '@cld-apis/types'
import { buildImageUrl } from 'cloudinary-build-url'
import clsx from 'clsx'
import emojiRegex from 'emoji-regex'
// import { type CSSProperties } from 'react'
import { toBase64 } from 'utils/misc'

// setConfig({
// 	cloudName: 'demo',
// })

const createImages = (images,) => {
	const imageBuilders = {}
	for (const [name, { id, alt, className, style }] of Object.entries(images)) {
		imageBuilders[name] = getImageBuilder(id, alt, { className, style })
	}
	return imageBuilders;		// as { [Name in keyof ImageType] }
}

function getImageBuilder(
	id,
	alt = '',
	{ className, style } = {},
) {
	// const src = buildImageUrl(id, {
	// 	cloud: {
	// 		cloudName: 'localhost',
	// 	},
	// 	transformations: {
	// 		resize: {
	// 			type: 'scale',
	// 			width: 500,
	// 			height: 500,
	// 		}
	// 	}
	// })
	
	function imageBuilder(transformations) {
		return buildImageUrl(id, { transformations })
	}
	// console.dir(src)
	imageBuilder.alt = alt
	imageBuilder.id = id
	imageBuilder.className = className
	imageBuilder.style = style
	// console.dir(imageBuilder)
	return imageBuilder
}

// const square = { aspectRatio: '1/1' }

const images = createImages({
	drama: {
		id: 'images/blog/drama-20240612-1400x600.jpg',
		alt: `school's drama`,
		style: { aspectRatio: '1.891' },
	},
	dinning: {
		id: 'images/ekass/dinninghall-20240612-1400x600.jpg',
		alt: `school's Dinning Hall`,
		style: { aspectRatio: '1.891' },
	},
	gate: {
		id: 'images/ekass/gate-1400x600.jpg',
		alt: `school's Gate`,
		style: { aspectRatio: '1.891' },
	},
	football: {
		id: 'images/ekass/footbal-20240612-1400x600.jpg',
		alt: `school's Football team`,
		style: { aspectRatio: '1.891' },
	},
	photo: {
		id: 'images/ekass/group_photo_20230916-1400x600.jpg',
		alt: `photo`,
		style: { aspectRatio: '1.891' },
	},
	farm: {
		id: 'images/ekass/farm-20240612-1400x600.jpg',
		alt: `school's farm`,
		style: { aspectRatio: '1.891' },
	},
	forest: {
		id: 'images/ekass/forest-20240612-1400x600.jpg',
		alt: `school's forest`,
		style: { aspectRatio: '1.891' },
	},
	teachers: {
		id: 'images/ekass/teachers-20240612-1400x600.jpg',
		alt: `teachers`,
		style: { aspectRatio: '1.891' },
	},
	science: {
		id: 'images/ekass/science-20240612-1400x600.jpg',
		alt: `science`,
		style: { aspectRatio: '1.891' },
	},
	adminblk: {
		id: 'images/ekass/adminblock-20240612-1400x600.jpg',
		alt: `administration block`,
		style: { aspectRatio: '1.891' },
	},
	kitchen: {
		id: 'images/ekass/kitchen-20240612-1400x600.jpg',
		alt: `kitchen`,
		style: { aspectRatio: '1.891' },
	},
})

// const illustrationImages = {
// 	teslaY: images.teslaY,
// 	solarPanels: images.solarPanels,
// 	snowboard: images.snowboard,
// 	skis: images.skis,
// 	kayak: images.kayak,
// 	onewheel: images.onewheel,
// 	microphone: images.microphone,
// 	helmet: images.helmet,
// }

function getImgProps( imageBuilder,	{ widths, sizes, transformations, className, style,	}) {
	const averageSize = Math.ceil(widths.reduce((a, s) => a + s) / widths.length)
	const aspectRatio = transformations?.resize?.aspectRatio
		? transformations.resize.aspectRatio.replace(':', '/')
		: transformations?.resize?.height && transformations.resize.width
			? `${transformations.resize.width}/${transformations.resize.height}`
			: imageBuilder.style?.aspectRatio
	// console.dir(averageSize)
	// console.dir({ width: averageSize, ...transformations?.resize })
	const imgProps = {
		style: {
			...imageBuilder.style,
			aspectRatio,
			...style,
		},
		className: clsx(imageBuilder.className, className),
		alt: imageBuilder.alt,
		// src: imageBuilder.id,
		// src: imageBuilder({
		// 	quality: 'auto',
		// 	format: 'auto',
		// 	...transformations,
		// 	resize: { width: averageSize, ...transformations?.resize },
		// }),
		src: "/api/upload/" + imageBuilder.id + "/w_" + averageSize + ",q_auto,f_auto",
		srcSet: widths
			.map((width) =>
				[
					// imageBuilder({
					// 	quality: 'auto',
					// 	format: 'auto',
					// 	...transformations,
					// 	resize: { width, ...transformations?.resize },
					// }),
					"/api/upload/" + imageBuilder.id + "/w_" + width + ",q_auto,f_auto",
					`${width}w`,
					
				].join(' '),
			)
			.join(', '),
		sizes: sizes.join(', '),
		crossOrigin: 'anonymous',
	}
	// {
	// 	"style": {
	// 		"aspectRatio": "1.891"
	// 	},
	// 	"className": "h-auto w-full object-contain motion-safe:animate-hero-image-reveal",
	// 	"alt": "school's drama",
	// 	"src": "https://res.cloudinary.com/demo/image/upload/w_918,q_auto,f_auto/images/blog/drama-20240612-1400x600.jpg",
	// 	"srcSet": "https://res.cloudinary.com/demo/image/upload/w_256,q_auto,f_auto/images/blog/drama-20240612-1400x600.jpg 256w, https://res.cloudinary.com/demo/image/upload/w_550,q_auto,f_auto/images/blog/drama-20240612-1400x600.jpg 550w, https://res.cloudinary.com/demo/image/upload/w_700,q_auto,f_auto/images/blog/drama-20240612-1400x600.jpg 700w, https://res.cloudinary.com/demo/image/upload/w_900,q_auto,f_auto/images/blog/drama-20240612-1400x600.jpg 900w, https://res.cloudinary.com/demo/image/upload/w_1300,q_auto,f_auto/images/blog/drama-20240612-1400x600.jpg 1300w, https://res.cloudinary.com/demo/image/upload/w_1800,q_auto,f_auto/images/blog/drama-20240612-1400x600.jpg 1800w",
	// 	"sizes": "(max-width: 1023px) 80vw, (min-width: 1024px) and (max-width: 1279px) 50vw, (min-width: 1280px) 900px",
	// 	"crossOrigin": "anonymous"
	// }

	// const src = imgProps.src;
	// console.dir(src);
	// imgProps.src = src.replace("https://res.cloudinary.com/demo/", "")
	// imgProps.srcSet = imgProps.srcSet.replaceAll("https://res.cloudinary.com/demo/", "")
	// console.dir(imgProps);
	return imgProps;
}


function getSocialImageWithPreTitle({ title, preTitle, featuredImage:img, url, }) {
	console.dir(img)
	const vars = `$th_1256,$tw_2400,$gw_$tw_div_24,$gh_$th_div_12`

	const encodedPreTitle = doubleEncode(emojiStrip(preTitle))
	const preTitleSection = `co_rgb:a9adc1,c_fit,g_north_west,w_$gw_mul_14,h_$gh,x_$gw_mul_1.5,y_$gh_mul_1.3,l_text:images/illustrations:Matter-Regular.woff2_50:${encodedPreTitle}`

	const encodedTitle = doubleEncode(emojiStrip(title))
	const titleSection = `co_white,c_fit,g_north_west,w_$gw_mul_13.5,h_$gh_mul_7,x_$gw_mul_1.5,y_$gh_mul_2.3,l_text:images/illustrations:Matter-Regular.woff2_110:${encodedTitle}`

	const kentProfileSection = `c_fit,g_north_west,r_max,w_$gw_mul_4,h_$gh_mul_3,x_$gw,y_$gh_mul_8,l_kent:profile-transparent`
	const kentNameSection = `co_rgb:a9adc1,c_fit,g_north_west,w_$gw_mul_5.5,h_$gh_mul_4,x_$gw_mul_4.5,y_$gh_mul_9,l_text:images/illustrations:Matter-Regular.woff2_70:Kent%20C.%20Dodds`

	const encodedUrl = doubleEncode(emojiStrip(url))
	const urlSection = `co_rgb:a9adc1,c_fit,g_north_west,w_$gw_mul_9,x_$gw_mul_4.5,y_$gh_mul_9.8,l_text:images:Matter-Regular.woff2_40:${encodedUrl}`

	const featuredImageIsRemote = img.startsWith('http')
	const featuredImageCloudinaryId = featuredImageIsRemote
		? toBase64(img)
		: img.replace(/\//g, ':')
	const featuredImageLayerType = featuredImageIsRemote ? 'l_fetch:' : 'l_'
	const featuredImageSection = `c_fill,ar_3:4,r_12,g_east,h_$gh_mul_10,x_$gw,${featuredImageLayerType}${featuredImageCloudinaryId}`

	return [
		`upload/image`,
		``,
		vars,
		preTitleSection,
		titleSection,
		kentProfileSection,
		kentNameSection,
		urlSection,
		featuredImageSection,
		`c_fill,w_$tw,h_$th/images/gate-1400x600.jpg`,
		// `c_fill,w_$tw,h_$th/images/illustrations/social-background.png`,
	].join('/')
}

function getGenericSocialImage({
	words,
	featuredImage: img,
	url,
}) {
	console.dir(url)
	const vars = `$th_1256,$tw_2400,$gw_$tw_div_24,$gh_$th_div_12`

	const encodedWords = doubleEncode(emojiStrip(words))
	const primaryWordsSection = `co_white,c_fit,g_north_west,w_$gw_mul_10,h_$gh_mul_7,x_$gw_mul_1.3,y_$gh_mul_1.5,l_text:images:Matter-Regular.woff2_110:${encodedWords}`

	const kentProfileSection = `c_fit,g_north_west,r_max,w_$gw_mul_4,h_$gh_mul_3,x_$gw,y_$gh_mul_8,l_kent:profile-transparent`
	const kentNameSection = `co_rgb:a9adc1,c_fit,g_north_west,w_$gw_mul_5.5,h_$gh_mul_4,x_$gw_mul_4.5,y_$gh_mul_9,l_text:images:Matter-Regular.woff2_70:Kent%20C.%20Dodds`

	const encodedUrl = doubleEncode(emojiStrip(url))
	const urlSection = `co_rgb:a9adc1,c_fit,g_north_west,w_$gw_mul_5.5,x_$gw_mul_4.5,y_$gh_mul_9.8,l_text:images:Matter-Regular.woff2_40:${encodedUrl}`

	const featuredImageIsRemote = img.startsWith('http')
	const featuredImageCloudinaryId = featuredImageIsRemote
		? toBase64(img)
		: img.replace(/\//g, ':')
	const featuredImageLayerType = featuredImageIsRemote ? 'l_fetch:' : 'l_'

	const featureImageSection = `c_fit,g_east,w_$gw_mul_11,h_$gh_mul_11,x_$gw,${featuredImageLayerType}${featuredImageCloudinaryId}`

	// const backgroundSection = `c_fill,w_$tw,h_$th/images/social-background.png`
	const backgroundSection = `social-background.png`
	// console.dir(`images/cloudinary/social-background.png`)

	return [
		`upload/image`,
		vars,
		primaryWordsSection,
		kentProfileSection,
		kentNameSection,
		urlSection,
		featureImageSection,
		backgroundSection,
	].join('/')
}

function emojiStrip(string) {
	return (
		string
			.replace(emojiRegex(), '')
			// get rid of double spaces:
			.split(' ')
			.filter(Boolean)
			.join(' ')
			.trim()
	)
}

// cloudinary needs double-encoding
function doubleEncode(s) {
	return encodeURIComponent(encodeURIComponent(s))
}
function BlogImage({ cloudinaryId, imgProps, transparentBackground, }) {
	return (
		<img
			// @ts-expect-error classname is overridden by getImgProps
			className="w-full rounded-lg object-cover py-8"
			{...getImgProps(getImageBuilder(cloudinaryId, imgProps.alt), {
				widths: [350, 550, 700, 845, 1250, 1700, 2550],
				sizes: [
					'(max-width:1023px) 80vw',
					'(min-width:1024px) and (max-width:1620px) 50vw',
					'850px',
				],
				transformations: {
					background: transparentBackground ? undefined : 'rgb:e6e9ee',
				},
			})}
			{...imgProps}
		/>
	)
}
export {
	images,
	BlogImage,
	getImgProps,
	getImageBuilder,
	getGenericSocialImage,
	getSocialImageWithPreTitle,
	// illustrationImages,
}
// export { ImageBuilder }
