/*
=========================================================
* States Grid - v1.1.0
=========================================================

* Copyright 2024

* Coded by KanukPt

=========================================================

*/
import React from 'react';

import Grid from "@mui/material/Grid";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography  from "@mui/material/Typography";
// import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
// import MKTypography from "components/MKTypography";

import DefaultNavbar from "layouts/sections/page-sections/Navbars/DefaultNavbar";
// import DefaultFooter from "layouts/sections/page-sections/page-footer";
// C:\apps\ekbl\src\layouts\sections\page-sections\Navbars\DefaultNavbar\index.js
// import Footer from "layouts/sections/page-sections/page-footer/sections/Footer";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

import axios from 'axios';
import {Helmet} from "react-helmet";
// import {useLocation} from 'react-router-dom';
// Image
// import bgImage from "assets/images/illustrations/illustration-reset.jpg";
// import HomePageHeader from "layouts/sections/page-sections/page-headers/components/HomePage";
// import GMap from "components/KKMap/gmap";

const ContactUs = () => {
	// const loc = useLocation();
	// console.dir(props)
	const [stateMesg, setstateMesg] = React.useState(null);
	const [checked, setChecked] = React.useState(false);
	const [email, setEmail] = React.useState("");
	const [fname, setFName] = React.useState("");
	const [mesg, setMesg] = React.useState("");
	const [error_mesg, setErrorMesg] = React.useState("");
	const [error_fname, setErrorFname] = React.useState("");
	const [error_email, setErrorEmail] = React.useState("");
	const [isSubmittingmesg, setisSubmitting] = React.useState(false);

	const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;

	const handleCheck = (event) => {
		setChecked(event.target.checked);
	};
	const handleFNameChange = (event) => {
		setFName(event.target.value);
	};
	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};
	const handleMesgChange = (event) => {
		setMesg(event.target.value);
	};
	const handleSend = (e) => {
		e.persist();
		e.preventDefault();
		// console.dir(email)
		// console.dir(regex.test(email.replace(/\s/g, '')));
		
		if (!regex.test(email.replace(/\s/g, ''))) {
			setErrorEmail("invalid email address");
			// console.dir(error_email)
			return;
		} else {
			setErrorEmail("");
		}
		if (fname.length<2) {
			setErrorFname("invalid name");
			// console.dir(error_fname)
			return;
		} else {
			setErrorFname("");
		}
		if (mesg.length<10) {
			setErrorMesg("enter message");
			return;
		} else {
			setErrorMesg("");
		}
		// console.dir("submitting...")
		setisSubmitting(true);
		const data = {
			"mesg": mesg,
			"email": email,
			"fname": fname,
		}
        axios({
            method: "post",
            // url: location.protocol + '//' + location.host + "/api/sendmsg",		
			url: "/api/sndmsg",		//location.protocol + '//' + "localhost:3000
            responseType: 'json',
            data: data,
            headers: {'Content-Type' : 'application/json','Accept' : 'application/json'},
        }).then(function () {
			// console.dir(response);
			// setMesg("");
			// setFName("");
			// setEmail("");
        }).catch(function (error) {
            console.error(error);
			setstateMesg("Something went wrong. Please try again.");
        }).then(function () {
            // always executed
			setTimeout(() => {
				setstateMesg(null);
			}, 5000)
		});
		// const formData = new FormData(event.currentTarget)
		// const formJson = Object.fromEntries(formData.entries());
		// const email = formJson.email;
		// const fname = formJson.fname;
		// const mesg = formJson.mesg
	}
	// console.dir(email)
	// console.dir(fname)
	// console.dir(mesg)
	console.dir(window.location)
	return (
		<>
			<DefaultNavbar 
				routes={routes}
				relative
				sticky
			/>
			<Helmet>
				<meta name="description" content="Send Ekambuli Secondary School a personal email" />
				<title>Contact Ekambuli High School</title>
				<meta name="og:url" content={window.location.href}></meta>
                <link rel="canonical" href={window.location.href} />
				<link rel="icon" href="/favicon.ico"></link>
				<meta name="og:title" content="Contact Ekambuli Secondary School"></meta>
				<meta name="og:description" content="Send Ekambuli Secondary School a personal email"></meta>
				<meta name="keywords" content="kenya, khwisero, luanda, kakamega, secondary, school, education, contact"></meta>
				<meta name="og:image" content={window.location.origin+'/images/ekass/gate-1400x600.jpg'}></meta>
				<meta name="robots" content="index,follow"></meta>
            </Helmet>
			<Box component="section" py={{ xs: 0, lg: 6 }} className="rounded-xl">
					<Grid container alignItems="center" spacing={0}>
						<Grid item xs={12} lg={6}>
							<Box
								// zindex={0}
								borderRadius="xl"
								shadow="lg"
								display="flex"
								flexDirection="column"
								justifyContent="center"
								// mt={{ xs: 10, sm: 10, md: 7 }}
								mb={{ xs: 5, sm: 5, md: 5 }}
								// mx={3}
								sx={{backgroundColor:"inherit"}}
							>
								{/* <GMap /> */}
								<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1994.9055299714234!2d34.56815143852399!3d0.10930534997240834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1780033dd7fd501b%3A0xb92398055a0d0170!2sEkambuli%20Secondary%20School!5e0!3m2!1sen!2ske!4v1718141191860!5m2!1sen!2ske" width="100%" height="505" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
							</Box>
						</Grid>
						{ isSubmittingmesg ? (
						// <Grid container alignItems="center">
							<Grid item xs={12} lg={6} >
								<Box
									className="mx-7vw"
									borderRadius="xl"
									shadow="lg"
									// width="100%"
									display="flex"
									// flexDirection="column"
									// justifyContent="center"
									// mt={{ xs: 5, sm: 11, md: 6 }}
									// mb={{ xs: 1, sm: 1, md: 1 }}
									sx={{backgroundColor:"inherit"}}
								>
									<Box
										variant="gradient"
										// bgColor="info"
										// coloredShadow="info"
										borderRadius="lg"
										width="100%"
										// mt={-3}
										sx={{backgroundColor: "#007C89", backgroundImage: "linear-gradient( #007C89, #FFFFFF)", opacity: 1 }}
									>
										<Typography variant="h3" color="white" align="center" >
											Your message was sent:
										</Typography>
										<Box shadow="lg" align="center">
											<ul>
												<li><i><b>Name</b></i>: {fname}</li>
												<li><i><b>Email:</b></i> {email}</li>
												<li><i><b>Message:</b></i> {mesg}</li>
											</ul>
										</Box>
									</Box>
								</Box>
							</Grid>
						// </Grid>
						) : (
						// <Grid container alignItems="center">
							<Grid item xs={12} lg={6}  
								// ml={{ xs: "auto", lg: 6 }}
								// mr={{ xs: "auto", lg: 6 }}
							>
								<Box
									borderRadius="xl"
									shadow="lg"
									// height={525}
									display="flex"
									flexDirection="column"
									justifyContent="center"
									// mt={{ xs: 5, sm: 11, md: 6 }}
									mb={{ xs: 1, sm: 1, md: 1 }}
									sx={{backgroundColor:"inherit", color: 'text'}}
									className="mx-7vw"
								>
									<Box
										variant="gradient"
										// coloredShadow="info"
										borderRadius="lg"
										// p={2}
										// mx={2}
										// mt={-3}
										sx={{backgroundColor:"info"}}
									>
										<Typography variant="h3" color="white">
											Contact us
										</Typography>
									</Box>
									<Box p={0}>
										<Typography variant="body2" color="text" pt={2} px={0}>
											For further questions, including partnership opportunities, please email 
											info@ekambuli.sc.ke or contact using our contact form.
										</Typography>
										<Box component="form" autoComplete="off" ml={0} mr={0}>
											<Grid container spacing={3}>
												<Grid item xs={12} md={6}>
													<MKInput
														variant="standard"
														label="Full Name"
														name='fname'
														InputLabelProps={{ shrink: true }}
														fullWidth
														placeholder="eg. Opiyo John"
														onChange={handleFNameChange}
														helperText={error_fname}
														FormHelperTextProps={{ sx: { color: error_fname ? 'red' : "black" } }}
													/>
												</Grid>
												<Grid item xs={12} md={6}>
													<MKInput
														type="email"
														name="email"
														variant="standard"
														label="Email"
														InputLabelProps={{ shrink: true }}
														fullWidth
														placeholder="hellofromm@gmail.com"
														onChange={handleEmailChange}
														helperText={error_email}
														FormHelperTextProps={{ sx: { color: error_email ? 'red' : "black" } }}
													/>
												</Grid>
												<Grid item xs={12}>
													<MKInput 
														variant="standard"
														label="Your message"
														name="mesg"
														placeholder="Describe your problem in at least 250 characters"
														InputLabelProps={{ shrink: true }}
														multiline
														fullWidth
														rows={6}
														onChange={handleMesgChange}
														helperText={error_mesg}
														FormHelperTextProps={{ sx: { color: error_mesg ? 'red' : "black" } }}
													/>
												</Grid>
											</Grid>
											<Grid container item justifyContent="center" xs={12} mt={0} mb={1}>
												<FormControlLabel
													label={
														<div className="text-dark">
															<span>I agree to the </span>
															<Link to={'/terms-of-use'}>terms of use</Link>
															<span> and </span>
															<Link to={'/privacy-policy'}>privacy policy</Link>
														</div>
													}
													control={<Checkbox checked={checked} onChange={handleCheck} />}
												/>
											</Grid>
											<Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
												<MKButton disabled={!checked || isSubmittingmesg} variant="gradient" color="info" onClick={handleSend}>
													Send Message
												</MKButton>
											</Grid>
											<Grid container item style={{color:"red"}} justifyContent="center" xs={12} mt={1} mb={2}>
												{stateMesg && <p>{stateMesg}</p>}
											</Grid>
										</Box>
									</Box>
								</Box>
							</Grid>
						)}
					</Grid>
			</Box>
		</>
	);
}

export default ContactUs;
