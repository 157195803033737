/**
=========================================================
* States Grid - v1.1.0
=========================================================

* Copyright 2024 States Gris

Coded by KanukPt

=========================================================

*/
import React, { useEffect } from "react";

// react-router components
import { Routes, Route, useLocation } from "react-router-dom";	//Navigate, 

// @mui material components
import { ThemeProvider, createTheme} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import { useTheme } from '@mui/material/styles';

// Material Kit 2 React themes
import theme from "assets/theme";
import Home from "layouts/pages/landing-pages/home";

// Material Kit 2 React routes
import routes from "routes";

import { GoogleOAuthProvider } from '@react-oauth/google';
// import IconButton from "@mui/material/IconButton";
// import Brightness4Icon from '@mui/icons-material/Brightness4';
// import Brightness7Icon from '@mui/icons-material/Brightness7';
export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });
// import { grey } from '@mui/material/colors';
import AuthProvider from "layouts/sections/components/Articles/context/AuthContext";

// import Main from "layouts/sections/components/Articles/main";
import NotFound from "layouts/sections/components/Articles/routes/NotFound";
import Profile from "layouts/sections/components/Articles/routes/Profile/Profile";
import ProfileArticles from "layouts/sections/components/Articles/routes/Profile/ProfileArticles";
import ProfileFavArticles from "layouts/sections/components/Articles/routes/Profile/ProfileFavArticles";
import Settings from "layouts/sections/components/Articles/routes/Settings";
import Article from "layouts/sections/components/Articles/routes/Article/Article";
import CommentsSection from "layouts/sections/components/Articles/routes/Article/CommentsSection";
import ArticleEditor from "layouts/sections/components/Articles/routes/ArticleEditor";
import BlogHome from "layouts/sections/components/Articles/routes/BlogHome";
import BlogArticles from "layouts/sections/components/Articles/routes/BlogArticles";
import BlogApp from "layouts/sections/components/Articles/BlogApp";
import Login from "layouts/sections/components/Articles/routes/Login"
// import { useAuth } from "layouts/sections/components/Articles/context/AuthContext";
import {theme as _theme} from "./blue";
import { SnackbarProvider } from 'notistack';

// import Brightness4Icon from '@mui/icons-material/Brightness4';
// import Brightness7Icon from '@mui/icons-material/Brightness7';
import Footer from "layouts/sections/page-sections/page-footer/sections/Footer";
import footerRoutes from "footer.routes";

function App() {
	// const { isAuth=false } = useAuth() || {};
	// console.dir(isAuth)

	// const theme = useTheme();
	// const colorMode = React.useContext(ColorModeContext);
	// const toggleMode = (e) => {
	// 	e.preventDefault();
	// 	e.stopPropagation();
    //     colorMode.toggleColorMode();
	// }



	const getRoutes = (allRoutes) =>
		allRoutes.map((route) => {
			if (route.collapse) {
				return getRoutes(route.collapse);
			}
			if (route.route) {
				return <Route exact path={route.route} element={route.component} key={route.key} />;
			}

			return null;
		});
	// console.dir(theme.palette.mode)
	return (
		<Box
			sx={{
				// display: 'flex',
				// width: '100%',
				// alignItems: 'center',
				// justifyContent: 'center',
				bgcolor: 'background.default',
				color: 'text.primary',
				borderRadius: 1,
				px: 0,
			}}			//  sx={{bgcolor: `${mode}.default`,}}
		>
			<>
				{/* <CssBaseline /> */}
			<AuthProvider>
				<Routes>
					{getRoutes(routes)}

					<Route element={<BlogApp />}>
						<Route path="/blog" element={<BlogHome />}>
							<Route index element={<BlogArticles />} />
						</Route>

						<Route path="/login" element={<Login />} />
						{/* <Route path="register" element={<SignUp />} /> */}

						<Route path="settings" element={<Settings />} />

						<Route path="blog/editor" element={<ArticleEditor />}>
							<Route path=":slug" element={<ArticleEditor />} />
						</Route>

						{/* <Route path="article/:slug" element={<Article />}>
							<Route index element={<CommentsSection />} />
						</Route> */}
						<Route path="blog/article/:slug" element={<Article />}>
							<Route index element={<CommentsSection />} />
						</Route>

						<Route path="profile/:username" element={<Profile />}>
							<Route index element={<ProfileArticles />} />
							<Route path="favorites" element={<ProfileFavArticles />} />
						</Route>
					</Route>

					<Route path="/*" element={<Home />} />
					{/* <Route path="/*" element={<Navigate to="/" />} /> */}
					<Route path="*" element={<NotFound />} />
				</Routes>
			</AuthProvider>
			</>
		</Box>
	)
}

export default function ToggleColorMode() {
	const { pathname } = useLocation();
	const [mode, setMode] = React.useState('light');
	useEffect(() => {
		let theMode = "light";
		if(window.matchMedia('(prefers-color-scheme: dark)').matches) {
			theMode = "dark";
			
		} else if('theme' in localStorage) {
			theMode = localStorage.theme
		} else if(theMode) {
			localStorage.theme = theMode
		}
		if(theMode === "dark") {
			document.documentElement.classList.add('dark')
		} else{
			document.documentElement.classList.remove('dark')
		}
		setMode(theMode);
	}, []);
	// const toggleMode = (e) => {
	// 	e.preventDefault();
	// 	e.stopPropagation();
    //     colorMode.toggleColorMode();
	// }
	const colorMode = React.useMemo(
		() => ({
			// toggleColorMode: () => {
			// 	console.dir(mode)
			// 	// const _mode = mode;
			// 	setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
			// },
			toggleColorMode: () => {
				// let prevMode = mode;
				// if (localStorage.theme === 'light' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: light)').matches)) {
				// 	prevMode = 'light';
				// }
				const theMode = localStorage.theme;
				// console.dir(theMode)
				const newMode = theMode === 'light' ? 'dark' : 'light';
				// console.dir(newMode)
				localStorage.theme = newMode;
				if(newMode==="dark") {
					document.documentElement.classList.add('dark')
				} else {
					document.documentElement.classList.remove('dark');
				}
				// console.log("document.documentElement.classList:",document.documentElement.classList, newMode)
				// do stuff with newCart
				setMode(newMode);
			},
		}),
		[],
	);
	// console.dir(mode)
	// let moded_theme = React.useMemo(
		
	// 	() => {
	// 		console.dir(mode);
	// 		return (
	// 			createTheme({
	// 				...theme,
	// 				palette: {
	// 					...theme.palette,
	// 					mode,
	// 					primary: {
	// 						main: '#90caf9',
	// 					},
	// 					secondary: {
	// 						main: '#f48fb1',
	// 					},
	// 				},					
	// 			})
	// 		)
	// 	},
	// 	[mode],
	// );
	// console.dir(mode)
	// Setting page scroll to 0 when changing the route
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);
	// const [mode, setMode] = React.useState<'light' | 'dark'>('light');
	// const toggleColorMode = () => {
	// 	// setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
	// 	theme.palette.mode = theme.palette.mode === 'light' ? 'dark' : 'light'
	// }
	// console.dir(mode)
	const modeTheme = React.useMemo(
		() => createTheme(_theme[mode]),
		[mode]
	);
	// const modeTheme = createTheme({
	// 	palette: {
	// 	  	mode,
	// 	},
	// });
	// console.dir(modeTheme)
	theme.palette.mode = mode
	theme.palette.background = { ...modeTheme.palette.background }
	theme.palette.primary = { ...modeTheme.palette.primary }
	theme.palette.secondary = { ...modeTheme.palette.secondary }
	theme.palette.text = { ...modeTheme.palette.text }
	theme.palette.success = { ...modeTheme.palette.success }
	theme.palette.error = { ...modeTheme.palette.error }
	theme.palette.info = { ...modeTheme.palette.info }
	theme.palette.warning = { ...modeTheme.palette.warning }
	theme.palette.divider = { ...modeTheme.palette.divider }
	theme.palette.tonalOffset = { ...modeTheme.palette.tonalOffset }
	theme.palette.upvote = { ...modeTheme.palette.upvote }
	theme.palette.downvote = { ...modeTheme.palette.downvote }
	// theme.palette.getContrastText = { ...modeTheme.palette.getContrastText }
	theme.palette.contrastThreshold = { ...modeTheme.palette.contrastThreshold }
	theme.palette.action = { ...modeTheme.palette.action }

	// theme.typography.h3 = {
	// 	fontSize: '1.2rem',
	// 	'@media (min-width:600px)': {
	// 	  	fontSize: '1.875rem',
	// 	},
	// 	[theme.breakpoints.up('md')]: {
	// 	  	fontSize: '2.5rem',
	// 	},
	// };
	// const getDesignTokens = () => ({
		// ...modeTheme,
		// ...theme,
		// palette: {
		// 	background: { ...modeTheme.palette.background},
		// 	white:{...modeTheme.palette}
		// },
	
		// palette: {
		// 	...theme.palette,
		// 	mode,
		// 	primary: {
		// 		// ...amber,
		// 		// ...(mode === 'dark' && {
		// 		// 	main: '#90caf9',
		// 		// }),
		// 		...theme.palette.light,
		// 	},
		// 	...(mode === 'dark' && {
		// 		background: {
		// 			// default: deepOrange[900],
		// 			default: '#121212',
		// 			// paper: deepOrange[900],
		// 			paper: '#fff',
		// 		},
		// 	}),
		// 	text: {
		// 		...(mode === 'light' ? 	
		// 		{
		// 			primary: grey[900],
		// 			secondary: grey[800],
		// 		}
		// 		:
		// 		{
		// 			primary: '#fff',
		// 			secondary: grey[500],
		// 		}),
		// 	},
		// },
	// });
	// enqueueSnackbar("Welcome...", {  })
	// moded_theme = createTheme(getDesignTokens());
	// console.dir(theme)
	return (
		<GoogleOAuthProvider clientId={"231460964636-a1s2vuo3c97ujfcaptvdcsmjbj92hfj7.apps.googleusercontent.com"} key="AIzaSyAfSR0dTvMeECJ_i4O_S1Xid1pN8NPWR8Y">
			<ColorModeContext.Provider value={colorMode}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<SnackbarProvider maxSnack={3} preventDuplicate anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} >
						<App color={"primary"}/>
						{/* <Typography component={"div"} sx={{maxWidth:"100%"}}
							variant="body2"
							opacity={0.5}
							mr={1}
							// ml={5}
							onClick={toggleMode}
							className="container flex justify-end"
						>
							<Typography component={"p"} sx={{cursor:"pointer", color:'text'}}>
								{theme.palette.mode === 'dark' ? <Brightness7Icon fontSize="small"/> : <Brightness4Icon fontSize="small"/>}
							</Typography>
						</Typography> */}
						<Footer menus={footerRoutes}/>
					</SnackbarProvider>
				</ThemeProvider>
			</ColorModeContext.Provider>
		</GoogleOAuthProvider>
	);
}

